import React from "react"

const RowsGen = (data, times) => {
  let x, y
  let rows = []
  let { benefit } = data
  for (x = 1; x < times; x++) {
    let cols = []
    for (y = 1; y < times; y++) {
      cols.push(
        <div
          className="squares--box d-flex align-items-center justify-content-center"
          key={y}
        >
          {benefit[Math.floor(Math.random() * benefit.length)]}
        </div>
      )
    }
    rows.push(
      <div className="row squares--row" key={x}>
        {cols}
      </div>
    )
  }
  return rows
}

const RenderCards = (data, times) => {
  let z
  let card = []
  for (z = 0; z < times; z++) {
    card.push(
      <div className="row justify-content-center bingo-card-generated" key={z}>
        <div className="col-12 bingo-card d-flex flex-column justify-content-center">
          <div className="bingo-card-container">
            <header className="bingo-card--header">
              <img
                className="logo"
                src={require("../images/new_logo.svg")}
                alt="r and r logo"
              />
            </header>
            <div className="bingo-card--body">
              <div className="row justify-content-center">
                <div className="squares">{RowsGen(data, 6)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return card
}

const BingoCard = props => {
  return (
    props.renderCards && (
      <>
        <div className="row justify-content-center print-screen-hide">
          <button onClick={() => window.print()} className="btn--print">
            Ready to Print
          </button>
        </div>
        {RenderCards(props.data, props.cardCount)}
      </>
    )
  )
}

export default BingoCard
