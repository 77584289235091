import React from "react"
import { graphql } from "gatsby"
import { Button, TextField } from "@material-ui/core"
import BingoCard from "../components/card"

import "../styles/index.scss"
import SEO from "../components/seo"

export default props => {
  const [cardCount, setCardCount] = React.useState(1)
  const [renderCards, setRenderCards] = React.useState(false)

  const _handleCardCount = e => {
    setCardCount(e.target.value)
    setRenderCards(false)
  }

  const _handleSubmit = () => {
    setRenderCards(true)
  }

  const benefits = props.data.sanityBenefitList
  return (
    <>
      <SEO title="R&R Bingo Card Generator" />
      <div className="main-wrapper">
        <header className="app-header" />
        <div className="row justify-content-center align-items-center card-count-wrapper">
          <div className="col-12 justify-content-center d-flex">
            <TextField
              label="How many Cards would you like to generate?"
              name="cardCount"
              type="number"
              className="col-6"
              onChange={_handleCardCount}
            />
          </div>
          <div className="col-12 justify-content-center d-flex">
            <Button
              onClick={_handleSubmit}
              style={{ marginTop: "25px" }}
              className="btn--primary-rr"
            >
              Generate
            </Button>
          </div>
        </div>
      </div>
      <div className="card-wrapper">
        <BingoCard
          cardCount={cardCount}
          renderCards={renderCards}
          data={benefits && benefits}
        />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query {
    sanityBenefitList {
      benefit
    }
  }
`
